import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withLocation from "./withLocation"
import TeamListing from "./teamlisting"
import SearchFormAlter from "./search/searchalter"
import { navigate, Link } from "gatsby"
import MonthlyOfficeSpaceLocationSwitch from "../components/monthlyofficespacelocationswitch"
import OfficeSpaceLocationSwitch from "../components/officespacelocationswitch"
import MeetingLocationSwitch from "../components/meetinglocationswitch"
const TeamSearchComponent = ({ search }) => {
  var { searchCollectionId } = search
  var { team } = search
  var { spaceType } = search
  const [listingFilter, setlistingFilter] = useState([])
  const [SpaceTypeFilter, setSpaceTypeFilter] = useState("dailyofficeSpace")
  const [Team, setTeam] = useState([])
  useEffect(() => {
    if (listingFilter == "") {
      setlistingFilter(spaceType)
    }
  }, [listingFilter])


  useEffect(() => {
    if (Team == "") {
      setTeam(team)
    }
  })
  useEffect(() => {
    if (SpaceTypeFilter == "") {
      setSpaceTypeFilter(spaceType)
    }
  })
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="listingpageTitle">{Team}</h1>
          </div>
          <div className="col-md-12">
            <div className="filterbar" style={{ marginTop: 15 }}>
              <ul className="SearchListingFilter scrollmenu">
               <li>
                  <a
                    onClick={e => {
                      setlistingFilter("dailyofficeSpace")
                      setSpaceTypeFilter("dailyofficeSpace")
                      var url =
                        "/teamsearch/?searchCollectionId=" +
                        searchCollectionId +
                        "&team="
                        +team +
                        "&spaceType=dailyofficeSpace"
                      navigate(url)
                    }}
                    className={
                      listingFilter === "dailyofficeSpace" ? "active" : ""
                    }
                  >
                    Coworking Spaces
                  </a>
                </li>
                <li>
                  <a
                    onClick={e => {
                      setlistingFilter("meetingSpace")
                      setSpaceTypeFilter("meetingSpace")
                      var url =
                        "/teamsearch/?searchCollectionId=" +
                        searchCollectionId +
                        "&team="
                        +team +
                        "&spaceType=meetingSpace"
                        
                      navigate(url)
                    }}
                    className={listingFilter === "meetingSpace" ? "active" : ""}
                  >
                    Meeting Spaces
                  </a>
                </li>
                <li>
                  <a
                    onClick={e => {
                      setlistingFilter("monthlyofficeSpace")
                      setSpaceTypeFilter("monthlyofficeSpace")
                      var url =
                        "/teamsearch/?searchCollectionId=" +
                        searchCollectionId +
                        "&team="
                        +team +
                        "&spaceType=monthlyofficeSpace"
                      navigate(url)
                    }}
                    className={
                      listingFilter === "monthlyofficeSpace" ? "active" : ""
                    }
                  >
                    Office Spaces
                  </a>
                  </li> 
              </ul>
            </div>
          </div>
        </div>
      </div>
      {searchCollectionId !== "" && SpaceTypeFilter === "dailyofficeSpace" ? (
        <TeamListing searchCollectionId={searchCollectionId} spacetype="dailyofficeSpace" />
      ) : (
        ""
      )}
      {searchCollectionId !== "" &&
      SpaceTypeFilter === "monthlyofficeSpace" ? (
        <TeamListing
        searchCollectionId={searchCollectionId}
          spacetype="monthlyofficeSpace"
        />
      ) : (
        ""
      )}
      {searchCollectionId !== "" &&
      SpaceTypeFilter === "meetingSpace" ? (
        <TeamListing searchCollectionId={searchCollectionId} spacetype="meetingSpace" />
      ) : (
        ""
      )}
    </div>
  )
}

TeamSearchComponent.propTypes = {
  search: PropTypes.object,
}

export default withLocation(TeamSearchComponent)
